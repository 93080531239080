@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
}


/* background images */
.about-bg {
  background-image: url('./assets/images/aboutbg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bg0shadow{
  background-color: rgba(0,0,0,0.8);
}